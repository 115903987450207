import axios from "./axiosConfig";

export const isRegisteredUser = (data)=> {
    return axios.post('/retailer/is-registered-user',data)
}

export const validateCustomer = (data) => {
    return axios.post('/retailer/validate-customer',data)
}

export const getQuestion = (params) => {
    return axios.get('/retailer/get-question',{ params })
}


export const claimReportDownload = (params) => {
    return axios.get('/retailer/claim-report-export', { params: params, responseType: 'blob' })
}
// claim-export


export const answerQuestion = (params) => {
    return axios.post('/retailer/answer-question',params)
}



export const retailerLogin = (data) => {
    return axios.post('/accounts/retailer-login',data)
}

export const eyeCheckup = () => {
    return axios.get('/retailer/eye-checkup')
}

export const createEyeCheckup = (data) => {
    return axios.post('/retailer/eye-checkup', {data});
}


export const getClaimReport = () => {
    return axios.get('/retailer/claim-report')
}



export const getCustomers = () => {
    return axios.get('/retailer/customers')
}

export const getCustomerDetails = (params) =>{
    return axios.get('/retailer/customers', { params })
}

export const isLoggedIn = () => {
    return !!localStorage.getItem('token');
}

export const getFrames = (params) => {
    return axios.get('/retailer/product-inventory', { params });
}

export const getLenses = (params) => {
    return axios.get('/retailer/lens-inventory', { params });
}

export const updateInventory = (data)=>{
    return axios.put('/retailer/product-inventory',data)              
}

export const addToCart = (products, flag) => {
    return axios.post('/retailer/cart', { products_skuid: products, flag });
}

export const getCart = (params) => {
    return axios.get('/retailer/cart', { params });
}

export const deleteProductFromCart = (cart_id, skuid, from) => {
    return axios.delete('/retailer/cart', { data: { cart_id, skuid, from } })
}

export const getProductDetails = (product_skuid, selected_color, selected_size) => {
    return axios.get('/retailer/product-details', { params: { product_skuid, selected_color, selected_size } })
}

export const placeOrder = (data) => {
    return axios.post('/retailer/order', data);
}

export const getThankYou = (order_id) => {
    return axios.get('/retailer/order', { params: { order_id } })
}

export const getOrders = () => {
    return axios.get('/retailer/order');
}

export const selectLens = (product_skuid, crt_id, lens_id) => {
    return axios.patch('/retailer/cart', { product_skuid, crt_id, lens_id, update_lens: true });
}

export const getPerscriptions = (customer_id) => {
    return axios.get('/retailer/get-prescription', { params: { customer_id } });
}

export const getOffers = () => {
    return axios.get('/retailer/get-offers');
}

export const addPrescription = (customer_id, prescription_id, mode, data, cart_id) => {
    return axios.post('/retailer/add-prescription', { customer_id, prescription_id, mode, data, cart_id });
}

export const applyOffer = (offer_id) => {
    return axios.post('/retailer/get-offers', { offer_id })
}

export const removeOffer = () => {
    return axios.delete('/retailer/get-offers');
}

export const getOrderDetails = (params) => {
    return axios.get('/retailer/order', { params })
}

export const getCartCount = () => {
    return axios.get('/retailer/get-cart-count');
}

export const removePrescription = (cart_id) => {
    return axios.delete('/retailer/add-prescription', { data: { cart_id } });
}

export const addAddress = (data) => {
    return axios.post('/retailer/add-address', data);
}

export const getAddress = (customer_id) => {
    return axios.get(`/retailer/add-address?customer_id=${customer_id}`)
}

export const getNotifications = () => {
    return axios.get('/retailer/notifications');
}

export const readNotification = (data) => {
    return axios.put('/retailer/notifications', data);
}

export const getQuestions = () => {
    return axios.get('/retailer/quiz');
}

export const submitQuiz = (option_id, question_id, customer_id) => {
    return axios.post('/retailer/quiz', { option_id, question_id, customer_id });
}

export const getPaymentStatus = (uuid) => {
    return axios.get('/retailer/payment-status', { params: { transaction_uuid: uuid } })
}

export const getPaymentModes = () => {
    return axios.get('/retailer/pay-modes');
}

export const payPartialAmount = (data) => {
    return axios.post('/retailer/pay-due', data);
}

export const resetPassword = (data) => {
    return axios.post('/accounts/change-password', data);
}

export const getProfile = () => {
    return axios.get('/retailer/profile');
}

export const sendInvoice = (data) => {
    return axios.post('/retailer/send-invoice', data);
}

export const downloadReport = (data) => {
    return axios.get('/retailer/export-order', { params: { 
        is_partial: data.is_partial || undefined,
        to_date: data.to_date || undefined,
        from_date: data.from_date || undefined 
    } , responseType: 'blob' });
}

export const forgotPassword = (data) => {
    return axios.post('/accounts/forgot-Password', data);
}

export const verifyToken = (params) => {
    return axios.get('/accounts/verify-token', { params })
}

export const changePassword = (data) => {
    return axios.post('/accounts/verify-and-makePassword', data);
}

export const logOut = () => {
    return axios.get('/accounts/user-logout');
}

export const addLensDiscount = (data) => {
    return axios.put('/retailer/cart', data);
}

export const removeDiscount = (data) => {
    return axios.delete('/retailer/cart', { data });
}

export const getAppoitments = () => {
    return axios.get(`/store/store_appointment`);
}

export const cancelAppoitment = (data) => {
    return axios.delete(`/store/store_appointment`, { data });
}