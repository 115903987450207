import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
  DialogTitle,
  Stack,
  IconButton,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import ClaimSuccessDialog from "./ClaimSuccessDialog";
import ClaimFailDialog from "./ClaimFailDialog";
import { answerQuestion, getQuestion } from "../../api/retailerApis";
import { Loader2, } from "../Loader";

const QnADialog = ({ open, order_id, onHide, setStatus }) => {
  const [claimSuccessPopup, setClaimSuccessPopup] = useState(false);
  const [claimfailPopup, setClaimfailPopup] = useState(false);
  const [check, setCheck] = useState(false);
  const [answer, setAnswer] = useState();
  const [loading, setLoading] = useState(true);
  const [seconds, setSeconds] = useState(15);
  const [timer, setTimer] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const initialState = {
    question: "What is the capital of India? ",
    opation: [],
  };
  const [question, setQuestion] = useState([]);
  const customer_id = localStorage.getItem("customerID");

  const payload = {
    order_id: order_id,
    customer_id: customer_id,
  };
  useEffect(() => {
    const res = getQuestion(payload).then((response) => {
      setLoading(false);

      if (response?.data?.status) {
        setQuestion(response?.data?.data);
      }
      setIsRunning(true)

    });
  }, [customer_id]);

  const handleOpenSuccessdialog = () => {
    setClaimSuccessPopup(true);
  };
  const selectOption = (e) => {
    setCheck(e?.target?.checked);
    setAnswer(e?.target?.value);
    // setClaimfailPopup(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isRunning && seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      } else {
        clearInterval(interval);
        if (isRunning) {
          AnswerSubmit();
        }
      }
    }, 1000);
    // Cleanup the interval on component unmount or when isRunning changes to false
    return () => clearInterval(interval);
  }, [seconds, isRunning]);

  function AnswerSubmit() {
    const params = {
      customer_id: customer_id,
      order_id: order_id,
      question_id: question?.id,
      answer: answer,
      is_timer: timer,
      // 'result': "Fail",
    };
    const res = answerQuestion(params).then((response) => {
      onHide();
      setLoading(false)

      if (response?.data?.status) {
        setClaimSuccessPopup(true);
      } else {
        setClaimfailPopup(true);
      }
    });
  }
  const submitAnswer = () => {
    setLoading(true)
    
    setIsRunning(false);  
    AnswerSubmit();
  };

  return (
    <>
      <Dialog open={open} onClose={onHide}>
        {loading ? (
          <>
            <Loader2 />
          </>
        ) : (
          <>
            {question ? (
              <>
                <div style={{ width: 475 }}>
                  <Stack
                    className="popupHeader"
                    style={{ justifyContent: "center", position: "relative" }}
                  >
                    <DialogTitle> Question </DialogTitle>
                    <IconButton
                      disableRipple
                      type="button"
                      onClick={onHide}
                      sx={{
                        p: "0px",
                        color: "#000",
                        position: "absolute",
                        right: "20px",
                      }}
                    >
                      <FontAwesomeIcon icon={faCircleXmark} />
                    </IconButton>
                  </Stack>
                  <DialogContent>
                    <div className="qustion-wrap">
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          {question?.question}
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                          className="qna-group"
                          onChange={(e) => {
                            selectOption(e);
                          }}
                        >
                          <FormControlLabel
                            value="option A"
                            control={<Radio />}
                            label={question["option A"]}
                          />
                          <FormControlLabel
                            value="option B"
                            control={<Radio />}
                            label={question["option B"]}
                          />
                          <FormControlLabel
                            value="option C"
                            control={<Radio />}
                            label={question["option C"]}
                          />
                          <FormControlLabel
                            value="option D"
                            control={<Radio />}
                            label={question["option D"]}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>

                    <Grid
                      container
                      component="main"
                      sx={{ justifyContent: "center" }}
                    >
                      <Grid item xs={12} sm={7} md={7}>
                        <Button
                          className="buttonPrimary medium uppercase"
                          variant="contained"
                          disabled={check ? false : true}
                          fullWidth
                          sx={{ mb: "25px" }}
                          onClick={submitAnswer}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                    <p className="timer-wrap">
                      Time left: <span>{seconds}</span>{" "}
                    </p>
                  </DialogContent>
                </div>
              </>
            ) : (
              <>
                <h1>"Something Went Wrong."</h1>

                {/* 

                    <div style={{ width: 475 }}>
                        <Stack className="popupHeader" style={{ justifyContent: 'center', position: 'relative' }}>
                            <DialogTitle> Question </DialogTitle>
                            <IconButton
                                disableRipple
                                type="button"
                                onClick={onHide}
                                sx={{ p: "0px", color: "#000", position: 'absolute', right: '20px' }}
                            >
                                <FontAwesomeIcon icon={faCircleXmark} />
                            </IconButton>
                        </Stack>
                        <DialogContent>
                            <div className="qustion-wrap">
                                <FormControl>
                                    <FormLabel id="demo-radio-buttons-group-label">What is the capital of India? </FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="female"
                                        name="radio-buttons-group"
                                        className="qna-group"
                                        onChange={(e) => { selectOption(e) }}
                                    >
                                        <FormControlLabel value="newDelhi" control={<Radio />} label="New Delhi" />
                                        <FormControlLabel value="kolkata" control={<Radio />} label="Kolkata" />
                                        <FormControlLabel value="mumbai" control={<Radio />} label="Mumbai" />
                                        <FormControlLabel value="chennai" control={<Radio />} label="Chennai" />
                                    </RadioGroup>
                                </FormControl>
                            </div>



                            <Grid container component="main" sx={{ justifyContent: "center" }}>
                                <Grid item xs={12} sm={7} md={7}>


                                    <Button
                                        className="buttonPrimary medium uppercase"
                                        variant="contained"
                                        disabled={check ? false : true}
                                        fullWidth
                                        sx={{ mb: "25px" }}
                                        onClick={submitAnswer}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </div> */}
              </>
            )}
          </>
        )}
      </Dialog>
      {claimSuccessPopup && (
        <ClaimSuccessDialog
          setStatus={setStatus}
          open={claimSuccessPopup}
          onHide={() => setClaimSuccessPopup(false)}
        />
      )}

      {claimfailPopup && (
        <ClaimFailDialog
          setStatus={setStatus}
          open={claimfailPopup}
          onHide={() => setClaimfailPopup(false)}
        />
      )}
    </>
  );
};

export default React.memo(QnADialog);
