import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
  DialogTitle,
  Stack,
  IconButton,
  Typography,
  Grid,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import successCheck from "../assets/images/success-check.svg";
import { useNavigate } from "react-router-dom";

function SuccessPopUp({ open, onHide }) {
  const navigate = useNavigate();

  const handleStartTest = () => {
    onHide();
    navigate('/new-eye-check');
  }

  return (
    <Dialog open={open} onClose={onHide}>
        <div style={{ width: 475 }}>
        <Stack className="popupHeader">
            <DialogTitle></DialogTitle>
            <IconButton
            disableRipple
            type="button"
            onClick={onHide}
            sx={{ p: "0px", color: "#000" }}
            >
            <FontAwesomeIcon icon={faCircleXmark} />
            </IconButton>
        </Stack>
        <DialogContent sx={{ textAlign: "center" }}>
            <img src={successCheck} alt="Not found" />
            <Typography
            variant="font22"
            component="p"
            sx={{ mt: 2, mb: 4, color: "#5B5B5B" }}
            >
            Customer has been verified now
            </Typography>
            <Grid container component="main" sx={{ justifyContent: "center" }}>
            <Grid item xs={12} sm={7} md={7}>
                <Button
                className="buttonPrimary medium uppercase"
                variant="contained"
                fullWidth
                sx={{ mb: "25px" }}
                onClick={handleStartTest}
                >
                Start eye test now
                </Button>
            </Grid>
            </Grid>
        </DialogContent>
        </div>
    </Dialog>
  )
}

export default React.memo(SuccessPopUp);
