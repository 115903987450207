import LoaderGif from '../assets/images/loader.gif'; 

export function Loader() {
  return (
    <div className='loader-wrap '>
        <img src={LoaderGif} alt='Loader Gif' width='200px' />
    </div>
  )
}




export function Loader2() {
  return (
    <div>
        <img src={LoaderGif} alt='Loader Gif' width='200px' />
    </div>
  )
}

