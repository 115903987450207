import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
  DialogTitle,
  InputLabel,
  Stack,
  IconButton,
  Snackbar,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { isRegisteredUser } from "../api/retailerApis";
import OtpModal from "./OtpModal";
import {Loader2} from "./Loader";

function SignUpModal({
  open,
  onHide,
  setOpen,
  isClaimCheck,
  order,
  perscription,
  handleUpload,
  isEyeCheck,
  mode,
  carts,
  is_partner,
  netPrice,
  claimHeading
}) {

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    order_id: '',
    email: '',
    number: ''
  });

  const [registered, setRegistered] = useState(false);
  const [otpPopUp, setOtpPopUp] = useState(false);
  const [emptyFields, setEmptyFields] = useState([]);
  const [emailError, setEmailError] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleBack = () => {
    setOtpPopUp(false);
    setOpen(true);
  }

  const validateForm = () => {
    const nameRegex = /^[A-Za-z]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;

    const isValidFirstName = nameRegex.test(form.firstName.trim());
    const isValidLastName = nameRegex.test(form?.lastName.trim());
    const isValidEmail = emailRegex.test(form.email.trim());
    const isValidNumber = phoneRegex.test(form.number);

    const emptyFields = [];
    if (!form.firstName) {
      emptyFields.push("First Name");
    }
    if (!form.lastName) {
      emptyFields.push("Last Name");
    }

    // {isClaimCheck == 'true' ?(
      
    // )}

    if (!form.number) {
      emptyFields.push("Mobile");
    }

    setEmptyFields(emptyFields);

    return isValidFirstName && (!form.email || isValidEmail) && isValidNumber && emptyFields.length === 0;
  }

  const handleCustomerDetailsSubmit = (e) => {
    e.preventDefault();
    setLoading(true)


    if (!validateForm()) {
      setSnackbarOpen(true);
      return;
    }
    const payload = {
      phone_number: form.number,
    };


    // if offine use play game with reteail side is claimCheck true 
    // if (isClaimCheck==='true') {
    //   onHide();
    //   setOtpPopUp(true);

    // }
    // when retail org. eye test camp then isClainm check no need to define

    isRegisteredUser(payload).then((response) => {


      if (response?.data?.status && response?.data?.data?.is_registered) {
        setRegistered(true);
        setOtpPopUp(true);


      } else {
        setRegistered(false);
        setOtpPopUp(true);

      }
      onHide();
      
      // setOtpPopUp(true);
      setLoading(false)

    });




  };

  const handleFormChange = (name, { target: { value } }) => {
    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(value);
      setEmailError(value && !isValidEmail);
    }

    if (name === "number") {
      const phoneRegex = /^\d{10}$/;
      const isValidNumber = phoneRegex.test(value);
      setNumberError(!isValidNumber);
    }

    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>

      <Dialog open={open} onClose={onHide}>
        {loading ? (

          <Loader2 />
        ) : (


          <div style={{ width: 475 }}>
            <Stack className="popupHeader">
              {isClaimCheck === 'true' ? (<>

                <DialogTitle style={{ textAlign: 'center' }}><span style={{ color: '#015490', fontWeight: '700', display: 'block' }}>Start the quiz for 100% Cashback </span> Customer detail</DialogTitle>

              </>) : (<>

                <DialogTitle><span style={{ color: '#015490', fontWeight: '700', display: 'block' }}>{claimHeading} </span> Customer detail</DialogTitle>
              </>)}


              <IconButton
                disableRipple
                type="button"
                onClick={onHide}
                sx={{ p: "0px", color: "#000" }}
              >
                <FontAwesomeIcon icon={faCircleXmark} />
              </IconButton>
            </Stack>

            <DialogContent>
              <form className="customForm" onSubmit={handleCustomerDetailsSubmit}>
                <InputLabel className="customLabel" htmlFor="name">
                  First Name
                </InputLabel>
                <TextField
                  className="customField"
                  placeholder="Enter first name."
                  value={form.firstName}
                  onChange={(e) => handleFormChange('firstName', e)}
                  fullWidth
                  id="firstName"
                  autoComplete="off"
                />
                <InputLabel className="customLabel" htmlFor="name">
                  Last Name
                </InputLabel>
                <TextField
                  className="customField"
                  placeholder="Enter last name."
                  value={form?.lastName}
                  onChange={(e) => handleFormChange('lastName', e)}
                  fullWidth
                  id="lastName"
                  autoComplete="off"
                />

                {isClaimCheck == 'true' ? (<>

                  <InputLabel className="customLabel" htmlFor="orderId">
                    Order ID
                  </InputLabel>
                  <TextField
                    className="customField"
                    placeholder="Enter Order ID."
                    value={form.order_id}
                    onChange={(e) => handleFormChange('order_id', e)}
                    fullWidth
                    id="order_id"
                    autoComplete="off"
                  />
                </>) : null}


                <InputLabel className="customLabel" htmlFor="number">E-mail</InputLabel>
                <TextField
                  className="customField"
                  placeholder="Enter Your Email"
                  value={form.email}
                  onChange={(e) => handleFormChange('email', e)}
                  fullWidth
                  id="email"
                  autoComplete="off"
                  error={emailError}
                  helperText={emailError ? "Please enter a valid email" : ""}
                />
                <InputLabel className="customLabel" htmlFor="number">
                  Mobile
                </InputLabel>
                <TextField
                  className="customField"
                  placeholder="Enter mobile no."
                  value={form.number}
                  onChange={(e) => handleFormChange('number', e)}
                  fullWidth
                  id="number"
                  error={numberError}
                  helperText={numberError ? "Please enter a valid mobile number" : ""}
                />
                <Button
                  className="buttonPrimary medium"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleCustomerDetailsSubmit}
                  sx={{ mb: "25px" }}
                  type="submit"
                >
                  SUBMIT
                </Button>
              </form>
            </DialogContent>
          </div>

        )}

      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        message="All fields are mandatory"
      />
    

        <OtpModal
          loading={loading}
          setLoading={setLoading}
          mode={mode}

          carts={carts}
          netPrice={netPrice}
          is_partner={is_partner}
          isEyeCheck={isEyeCheck}
          isClaimCheck={isClaimCheck}
          setForm={setForm}
          open={otpPopUp}
          handleBack={handleBack}
          handleUpload={handleUpload}
          onHide={() => setOtpPopUp(false)}
          form={form}
          registered={registered}
          perscription={perscription}
          order={order}
        />
    </>
  )
}

export default React.memo(SignUpModal);
