import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
  DialogTitle,
  InputLabel,
  Stack,
  IconButton,
  Snackbar,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import OtpModal from "../OtpModal";

function CustomerDetailDialog({ open, onHide, setOpen}) {

  const [form, setForm] = useState({
    fullName: '',
    orderId: '',
    email: '',
    number: ''
  });

  const [registered, setRegistered] = useState(false);
  const [otpPopUp, setOtpPopUp] = useState(false);
  const [emptyFields, setEmptyFields] = useState([]);
  const [emailError, setEmailError] = useState(false);
  const [numberError, setNumberError] = useState(false);

  const handleBack = () => {
    setOtpPopUp(false);
    setOpen(true);
  }

  const validateForm = () => {
    const nameRegex = /^[A-Za-z]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;
    // const orderRegex = /^([A-Z]{2})\d{13}$/;
    // const isValidorderId = orderRegex.test(form.orderId.trim());
    const orderRegex = /^([A-Z]{2})\d{13}$/;

    const orderId = form.orderId.trim(); // Get the trimmed order ID    
    if (orderRegex.test(orderId)) {
      // Order ID is valid, do nothing
    } else {
      // Order ID is invalid, show error message
      const errorMessage = `Invalid order ID format. Please enter a valid ID starting with two uppercase letters and followed by 13 digits.`;
      // Update the UI to display the error message, e.g., using an alert or displaying it near the input field
      alert(errorMessage);
    }
    
    const isValidorderId = orderRegex.test(form.orderId); // Update isValidorderId after the check
    
    const isValidfullName = nameRegex.test(form.fullName.trim());
    const isValidEmail = emailRegex.test(form.email.trim());
    const isValidNumber = phoneRegex.test(form.number);

    const emptyFields = [];
    if (!form.fullName) {
      emptyFields.push("First Name");
    }
    if (!form.orderId) {
      emptyFields.push("Last Name");
    }

    if (!form.number) {
      emptyFields.push("Mobile");
    }

    setEmptyFields(emptyFields);

    return isValidfullName && isValidorderId && (!form.email || isValidEmail) && isValidNumber && emptyFields.length === 0;
  }

  const handleCustomerDetailsSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setSnackbarOpen(true);
      return;
    }

    const payload = {
      phone_number: form.number,
    };

    // isRegisteredUser(payload).then((response) => {
    //   if (response?.data?.status && response?.data?.data?.is_registered) {
    setRegistered(true);
    //   } else {
//     setRegistered(false);
    //   }
    onHide();
    setOtpPopUp(true);
    // });
  };

  const handleFormChange = (name, { target: { value } }) => {
    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(value);
      setEmailError(value && !isValidEmail);
    }

    if (name === "number") {
      const phoneRegex = /^\d{10}$/;
      const isValidNumber = phoneRegex.test(value);
      setNumberError(!isValidNumber);
    }

    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onHide}>
        <div style={{ width: 475 }}>
          <Stack className="popupHeader" style={{ justifyContent: 'center', position: 'relative' }}>
            <DialogTitle style={{textAlign:'center'}}><span style={{color:'#015490', fontWeight:'700', display:'block'}}>Start the quize for 100% Caseback </span> Customer detail</DialogTitle>
            <IconButton
              disableRipple
              type="button"
              onClick={onHide}
              sx={{ p: "0px", color: "#000", position: 'absolute', right: '20px'  }}
            >
              <FontAwesomeIcon icon={faCircleXmark} />
            </IconButton>
          </Stack>
          <DialogContent>
            <form className="customForm" onSubmit={handleCustomerDetailsSubmit}>
              <InputLabel className="customLabel" htmlFor="name">
                Full Name
              </InputLabel>
              <TextField
                className="customField"
                placeholder="Enter first name."
                value={form.fullName}
                onChange={(e) => handleFormChange('fullName', e)}
                fullWidth
                id="fullName"
                autoComplete="off"
              />

              <InputLabel className="customLabel" htmlFor="orderId">
                Order ID
              </InputLabel>
              <TextField
                className="customField"
                placeholder="Enter Order ID."
                value={form.orderId}
                onChange={(e) => handleFormChange('orderId', e)}
                fullWidth
                id="orderId"
                autoComplete="off"
              />

              <InputLabel className="customLabel" htmlFor="number">E-mail</InputLabel>
              <TextField
                className="customField"
                placeholder="Enter Your Email"
                value={form.email}
                onChange={(e) => handleFormChange('email', e)}
                fullWidth
                id="email"
                autoComplete="off"
                error={emailError}
                helperText={emailError ? "Please enter a valid email" : ""}
              />
              <InputLabel className="customLabel" htmlFor="number">
                Mobile
              </InputLabel>
              <TextField
                className="customField"
                placeholder="Enter mobile no."
                value={form.number}
                onChange={(e) => handleFormChange('number', e)}
                fullWidth
                id="number"
                error={numberError}
                helperText={numberError ? "Please enter a valid mobile number" : ""}
              />
              <Button
                className="buttonPrimary medium"
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleCustomerDetailsSubmit}
                sx={{ mb: "25px" }}
                type="submit"
              >
                SUBMIT
              </Button>
            </form>
          </DialogContent>
        </div>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        message="All fields are mandatory"
      />
      <OtpModal
        open={otpPopUp}
        handleBack={handleBack}
        onHide={() => setOtpPopUp(false)}
        form={form}
        registered={registered}
      />
    </>
  )
}

export default React.memo(CustomerDetailDialog);
