import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle, Stack, IconButton, Typography, Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import TermConditionDialog from "./TermConditionDialog";
import QnADialog from "./QnADialog";

const StartGameDialog = ({ open, onHide, setStartGamePopup,setForm }) => {
  const [termConditionPopup, setTermConditionPopup] = useState(false);
  const [qnaPopup, setQnaPopup] = useState(false);
  const [status, setStatus] = useState(0)

  let order_id= localStorage.getItem("orderId",'')


  const handleOpenTCdialog = () => {
    setTermConditionPopup(true);
    // setStartGamePopup(false);
    setStatus(1)
  };

  const handleCloseTCdialog = () => {
    setTermConditionPopup(false);
    
    setStartGamePopup(false);

  }

  return (
    <>
      
      <div>


        {(() => {

          switch (status) {
            case 0:
              return (
                <Dialog open={open} onClose={onHide}>
                <div style={{ width: 475 }}>
                  <Stack className="popupHeader" style={{ justifyContent: 'center', position: 'relative' }}>
                    <DialogTitle> Verification Completed</DialogTitle>
                    <IconButton
                      disableRipple
                      type="button"
                      onClick={onHide}
                      sx={{ p: "0px", color: "#000", position: 'absolute', right: '20px' }}
                    >
                      <FontAwesomeIcon icon={faCircleXmark} />
                    </IconButton>
                  </Stack>
                  <DialogContent sx={{ textAlign: "center" }}>
        
                    <Typography
                      variant="font22"
                      component="p"
                      sx={{ mt: 2, mb: 4, color: "#008000" }}
                    >
                      Congratulation!! <br />
                      You are Eligible to play the game
                    </Typography>
        
                    <Grid container component="main" sx={{ justifyContent: "center" }}>
                      <Grid item xs={12} sm={7} md={7}>
                        <Button
                          className="buttonPrimary medium uppercase"
                          variant="contained"
                          fullWidth
                          sx={{ mb: "25px" }}
                          onClick={handleOpenTCdialog}
                        >
                          Start the Game
                        </Button>
                      </Grid>
                    </Grid>
                  </DialogContent>
                </div>
        
              </Dialog>
                
              )
            

              // ... return your JSX for mode "a" here ...
              break;
            case 1:
              return(
                <TermConditionDialog open={termConditionPopup}
                onHide={() => setTermConditionPopup(false)}
                setStatus={setStatus}
                
                
                setQnaPopup={setQnaPopup}
                onCloseTCPopup={handleCloseTCdialog} />
              )
              // ... return your JSX for mode "b" here ...
              break;
            case 2 :
              return(
                <QnADialog open={termConditionPopup} 
                onHide={() => setTermConditionPopup(false)}
                order_id={order_id}

                setStatus={setStatus} />
            )
              // ... return your JSX for mode "c" here ...
              break;
            default:
              return 
              // ... return your default JSX here ...
              break;
          }

        })()}

      </div>




    </>
  )
}

export default React.memo(StartGameDialog);
