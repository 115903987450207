import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
    DialogTitle,
    Stack,
    IconButton,
    Typography,
    Grid,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

function AllreadyPlayed({ open, onHide, orderId}) {

    return (
        <Dialog open={open} onClose={onHide}>
            <div style={{ width: 475 }}>
                <Stack className="popupHeader" style={{ justifyContent: 'center', position: 'relative' }}>
                    {/* <DialogTitle>All ready Play!</DialogTitle> */}
                    <IconButton
                        disableRipple
                        type="button"
                        onClick={onHide}
                        sx={{ p: "0px", color: "#000", position: 'absolute', right: '20px'  }}
                    >
                        <FontAwesomeIcon icon={faCircleXmark} />
                    </IconButton>
                </Stack>
                <DialogContent sx={{ textAlign: "center" }}>
                    <FontAwesomeIcon icon={faCircleXmark} style={{fontSize:'50px', color:'#ff0000'}} />
                    <Typography
                        variant="font22"
                        component="p"
                        sx={{ mt: 2, mb: 4, color: "#5B5B5B" }}
                    > Oops! <br /> You have already Tried the game with this  {orderId}.
                    </Typography>
                    <Grid container component="main" sx={{ justifyContent: "center" }}>
                        <Grid item xs={12} sm={7} md={7}>
                            <Button
                                className="buttonPrimary medium uppercase"
                                variant="contained"
                                fullWidth
                                sx={{ mb: "25px" }}
                                onClick={(e)=>{
                                    onHide()
                                    window.location.reload();

                                }}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </div>
        </Dialog>
    )
}

export default React.memo(AllreadyPlayed);
