import * as React from 'react';
import Box from '@mui/material/Box';
import { IconButton, Grid, Avatar, Paper, InputBase, Button, Typography, Stack } from '@mui/material';
import './../assets/scss/headerStyle.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faMagnifyingGlass,faAngleDown} from '@fortawesome/free-solid-svg-icons';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import BellIcon from './../assets/images/notification-icon.svg';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getCartCount, logOut } from '../api/retailerApis';

function FadeMenu() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    logOut().then((res) => {
      if(res?.data?.status) {
        localStorage.removeItem('token');
        localStorage.removeItem('name');
        navigate('/');
      }
    });
  }

  return (
    <div className='userInfoWrap'>
      <Button id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className='userInfoToggle'
        sx={{p:'0'}}
      >
       {localStorage.getItem('name')} <FontAwesomeIcon icon={faAngleDown} />
      </Button>
      <Menu
      className='menu-dropdown'
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={() => {
          handleClose();
          navigate('/profile');
        }}>Profile</MenuItem>
        <MenuItem onClick={handleLogOut}>Log Out</MenuItem>
      </Menu>
      
      <Typography variant='font12' component="p" sx={{color:'#737791' }}>Retailer</Typography>
    </div>
  );
}

export default function Header() {
  const navigate = useNavigate();
  const { data } = useQuery(['cart-count'], async () => {
    const response = await getCartCount();
    if(response?.data?.status) {
      return response?.data;
    }
    return 0;
  }, {
    retry: false
  });

  return (
    <Stack className="header" sx={{background:'#fff',boxShadow:'none'}}>
      <Grid container spacing={2} sx={{alignItems:'center'}}>
        <Grid item xs={5} md={5}>
          {/* <Paper component="form" className='headerSearchWrap' >
            <InputBase
              placeholder="Search..."
              inputProps={{ 'aria-label': 'Search...' }}
            />
            <IconButton type="button" sx={{ p: '0px',fontSize:'18px',color:'#2B7DCD' }}  aria-label="search">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
            </IconButton>
          </Paper> */}
        </Grid>
        <Grid item xs={7} md={7} >
          <Box sx={{ display: 'flex',justifyContent:'end',alignItems:'center'}}>
            <IconButton className='iocnButton' onClick={() => navigate('/notifications')} >
                <span className='activeIcon'>{data?.is_read_false_count || 0}</span>
                <img src={BellIcon} alt="Notification" />
            </IconButton>
            <IconButton className='iocnButton' onClick={() => navigate('/cart')} >
                <span className='activeIcon'>{data?.count || 0}</span>
                <FontAwesomeIcon icon={faCartShopping} width={'16px'} />
            </IconButton>
            <FadeMenu />
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
}
