import React, { useState } from 'react';
import { Typography, Grid, Paper, Stack, Divider, Button, InputLabel, Avatar } from '@mui/material';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Avtaar from '../assets/images/profile.png';
import EditProfile from '../components/EditProfile';
import EditIcon from '../assets/images/edit.svg';
import { getProfile, resetPassword } from '../api/retailerApis';
import { useQuery } from '@tanstack/react-query';
import {Loader} from '../components/Loader';

const RetailerProfile = () => {
    const [form, setForm] = useState({
        old_password: '',
        new_password: '',
        confirm_password: ''
    })
    const [isEdit, setIsEdit] = useState(false);
    const { isLoading, data: profile } = useQuery(['profile'], async () => {
        const response = await getProfile();
        if(response?.data?.status) {
            return response.data;
        }
        return {}
    }, {
        retry: false
    });

    if(isLoading) {
        return <Loader />
    }

    const handleChange = ({ target: { name, value } }) => {
        setForm({
            ...form,
            [name]: value
        });
    }

    const handleSubmit = () => {
        resetPassword(form).then((response) => {
            if(response?.data?.status) {
                setIsEdit(false);
                setForm({
                    old_password: '',
                    new_password: '',
                    confirm_password: ''
                });
            }
        })
    }

    return (
        <div className='profile-outer'>
            <Typography mb={3} variant='font22' sx={{ fontWeight: '700', cursor: 'pointer' }} component="h1"> Profile </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} >
                    <Paper direction="column" sx={{ p: 3, mb: 2, width: '100%', boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.05)' }}>
                        <Stack direction="row" spacing={4} sx={{ justifyContent: 'space-between', alignItems: 'flexStart' }}>
                            <Avatar
                                alt="Retailer Profile"
                                src={process.env.REACT_APP_API_URL + profile?.retailer?.profile_image || Avtaar}
                                sx={{ width: 95, height: 95, borderRadius: '50%' }}
                            />
                        </Stack>

                        <ul>
                            <li><span>First Name</span>{profile?.retailer?.first_name}</li>
                            <li><span>Last Name</span>{profile?.retailer?.last_name}</li>
                            <li className='full-wrap'><span>Address</span>{profile?.store?.address}</li>
                            <li><span>Store  ID</span>{profile?.store?.store_code}</li>
                            <li><span>Retail Shop Name</span>{profile?.store?.store_name}</li>
                            <li><span>Contact Number</span>{profile?.retailer?.phone_number}</li>
                            <li><span>Email Address</span>{profile?.retailer?.email}</li>
                            <li><span>GST Number</span>{profile?.store?.gst_number} </li>
                            <li><span>PAN Number</span>{profile?.store?.pan_number}</li>
                        </ul>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} >
                    {/* <Paper direction="column" sx={{ p: 3, mb: 2, width: '100%', boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.05)' }}> */}
                        {/* <Stack direction="row" spacing={4} sx={{ justifyContent: 'space-between', alignItems: 'flexStart' }}> */}
                        {/* <Typography variant='font20' sx={{ fontWeight: '700', mb: 3, }} component="h4"> Change password</Typography>
                        {
                            !isEdit ? 
                                <Button onClick={() => setIsEdit(true)} className='buttonPrimary edit-btn small' variant="contained" sx={{ width: 'auto', display: 'block', }}> Edit <img src={EditIcon} alt="Edit" />  </Button> 
                            : 
                                <Button onClick={() => {
                                    setForm({
                                        old_password: '',
                                        new_password: '',
                                        confirm_password: ''
                                    });
                                    setIsEdit(false);
                                }} className='buttonPrimary cancel-btn small' variant="contained" sx={{ width: 'auto', display: 'block', }}> Cancel</Button>
                        }
                        </Stack> */}
                        {/* <form className='customForm'>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <InputLabel className="customLabel" htmlFor="name"> Current Password </InputLabel>
                                    <TextField
                                        className="customField"
                                        placeholder="Enter Current Password"
                                        value={form.old_password}
                                        fullWidth
                                        name="old_password"
                                        id="old_password"
                                        autoComplete="off"
                                        onChange={handleChange}
                                        disabled={!isEdit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel className="customLabel" htmlFor="mobile_number"> New Password </InputLabel>
                                    <TextField
                                        className="customField"
                                        placeholder="Enter New Password"
                                        value={form.new_password}
                                        fullWidth
                                        name="new_password"
                                        id="new_password"
                                        onChange={handleChange}
                                        autoComplete="off"
                                        disabled={!isEdit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel className="customLabel" htmlFor="mobile_number"> Confirm Password </InputLabel>
                                    <TextField
                                        className="customField"
                                        placeholder="Enter Confirm Password"
                                        value={form.confirm_password}
                                        fullWidth
                                        onChange={handleChange}
                                        name="confirm_password"
                                        id="confirm_password"
                                        autoComplete="off"
                                        disabled={!isEdit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button onClick={handleSubmit} className='buttonPrimary medium uppercase' variant="contained" sx={{ width: '100%', display: 'block', marginBottom: '30px' }}> Submit </Button>
                                </Grid>
                            </Grid>
                        </form> */}
                    {/* </Paper> */}
                </Grid>
            </Grid>
        </div>
    )
}

export default RetailerProfile