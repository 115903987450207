import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
    DialogTitle,
    Stack,
    IconButton,
    Typography,
    Grid,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import {  validateCustomer } from "../api/retailerApis";
import SuccessPopUp from "./SuccessPopUp";
import StartGameDialog from "./claim/StartGameDialog";
import {Loader2} from "./Loader";
import AllreadyPlayed from "./claim/AllreadyPlayed";

function OtpModal({
    open,
    onHide,
    form,
    setForm,
    registered,
    handleBack,
    order,
    perscription,
    handleUpload,
    carts,
    is_partner,
    isEyeCheck,
    netPrice,
    mode,
    isClaimCheck

}) {
    const { number, firstName, lastName, email, order_id } = form;
    const [timer, setTimer] = useState(50);
    const [otp, setOtp] = useState('');
    const [sucessPopup, setSucessPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userDetail, setUserDetail] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        let intervalId;
        if (open) {
            intervalId = setInterval(() => {
                if (timer - 1 < 0) {
                    setTimer(0);
                } else {
                    setTimer((prevTimer) => prevTimer - 1);
                }
            }, 1000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [open, timer]);

    const handleOtpSubmit = () => {
        setLoading(true)
        localStorage.setItem("orderId", order_id)


        const payload = {
            phone_number: number,
            first_name: firstName,
            order_id: order_id,
            last_name: lastName,
            email: email,
            is_registered: true,
            otp: otp
        };

        if (registered) {
            validateCustomer(payload).then((response) => {
                const _id = response?.data?.customer?.id
                localStorage.setItem("customerID", _id)
                if (response?.data?.status) {
                    // onHide();
                    setUserDetail(response?.data)

                    if (order && !perscription) {
                        setSucessPopup(true)

                        navigate('/offers', { state: { carts: carts, from: 'cart', is_partner: is_partner, netPrice: netPrice } });
                    } else if (perscription && !order) {
                        handleUpload(true, mode);
                    } else {
                        setSucessPopup(true);
                    }
                }
                setLoading(false)
                onHide(false)
                setForm({
                    firstName: '',
                    lastName: '',
                    order_id: '',
                    email: '',
                    number: ''
                  })



            })
        } else {
            const payload = {
                phone_number: number,
                first_name: firstName,
                order_id: order_id,
                last_name: lastName,
                email: email,
                is_registered: false,
                otp: otp
            };
            validateCustomer(payload).then((response) => {

                if (response?.data?.status) {
                    let _id= response?.data?.customer?.id
                    localStorage.setItem("customerID", _id)

                    
                    setSucessPopup(true)
                    setLoading(false)
                    setUserDetail(response?.data)

                    if (order && !perscription) {
                        navigate('/offers', { state: { carts: carts, from: 'cart', is_partner: is_partner, netPrice: netPrice } });
                    } else if (perscription && !order) {
                        handleUpload(true);
                    } else {
                        setSucessPopup(true);
                    }

                }
                setLoading(false)
                onHide();
                setForm({
                    firstName: '',
                    lastName: '',
                    order_id: '',
                    email: '',
                    number: ''
                  })


            })
        }
        setOtp("")
    }

    return (
        <>
            <div>







                <Dialog open={open} onClose={onHide}>


                    {loading ? (<Loader2 />) : (

                        <div style={{ width: 475 }}>
                            <Stack className="popupHeader">
                                <DialogTitle>OTP</DialogTitle>
                                <IconButton
                                    disableRipple
                                    type="button"
                                    onClick={onHide}
                                    sx={{ p: "0px", color: "#000" }}
                                >
                                    <FontAwesomeIcon icon={faCircleXmark} />
                                </IconButton>
                            </Stack>

                            <DialogContent>

                                <Typography sx={{ mb: 5, color: "#3D3855" }}>
                                    Sent a 4 digit code on customer mobile no{" "}
                                    {isClaimCheck === 'true' ? (<>

                                        <strong>+91 {number}</strong>
                                        <Link to="#" onClick={handleBack}>Change</Link>


                                    </>) : (
                                        <>
                                            <strong>+91 {number}</strong> as him/her for proceed the eye
                                            checkup
                                            <Link to="#" onClick={handleBack}>Change</Link>

                                        </>
                                    )}
                                </Typography>
                                <form className="customForm">
                                    <OtpInput
                                        inputStyle="customOtpField"
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={4}

                                        
                                        renderSeparator={<span></span>}
                                        renderInput={(props) => <input {...props} />}
                                    />
                                    <Grid
                                        container
                                        component="main"
                                        sx={{ mt: 7, justifyContent: "space-between" }}
                                    >
                                        <Grid item xs={false} sm={5} md={5}>
                                            <Button
                                                className="buttonOutline medium"
                                                variant="contained"
                                                fullWidth
                                                sx={{ mb: "25px" }}
                                                onClick={handleBack}
                                            >
                                                BACK
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={5}>
                                            <Button
                                                className="buttonPrimary medium"
                                                variant="contained"
                                                fullWidth
                                                sx={{ mb: "25px" }}
                                                onClick={handleOtpSubmit}

                                            >
                                                SUBMIT
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Typography
                                        variant="font14"
                                        sx={{ textAlign: "center", mt: 5, fontWeight: "700" }}
                                    >
                                        {" "}
                                        {`00:${timer < 10 ? `0${timer}` : timer}`}
                                    </Typography>
                                </form>
                            </DialogContent>
                        </div>



                    )}



                </Dialog>


                {
                    isEyeCheck && (
                        <SuccessPopUp open={sucessPopup} onHide={() => setSucessPopup(false)} />

                    )
                }

                {isClaimCheck === 'true' ? (

                    <>
                        {userDetail && <>

                            {

                                userDetail?.claimstatus ? (<>

                                    <StartGameDialog
                                     open={sucessPopup}
                                        order_id={order_id}
                                        setStartGamePopup={setSucessPopup}
                                        onHide={() => setSucessPopup(false)}
                                    />
                                </>) : (
                                    <>
                                        <AllreadyPlayed open={sucessPopup}

                                            orderId={order_id}

                                            onHide={() => setSucessPopup(false)}
                                        />



                                    </>
                                )
                            }


                        </>}




                    </>
                ) : null


                }

            </div>



        </>
    )
}

export default React.memo(OtpModal);
