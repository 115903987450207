import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
  DialogTitle,
  Stack,
  IconButton,
  Typography,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import QnADialog from "./QnADialog";

const TermConditionDialog = ({ open, onHide, onCloseTCPopup, setStatus }) => {
  const [qnaPopup, setQnaPopup] = useState(false);
  const [check, setCheck] = useState(false);

  const handleOpenQnAdialog = () => {
    setQnaPopup(true);
    setStatus(2);
    // onHide()
    // onCloseTCPopup()
  };

  return (
    <>
      <Dialog open={open} onHide={onHide}>
        <div style={{ width: 475 }}>
          <Stack
            className="popupHeader"
            style={{ justifyContent: "center", position: "relative" }}
          >
            <DialogTitle> Claim Terms and Condition </DialogTitle>
            <IconButton
              disableRipple
              type="button"
              onClick={onHide}
              sx={{
                p: "0px",
                color: "#000",
                position: "absolute",
                right: "20px",
              }}
            >
              <FontAwesomeIcon icon={faCircleXmark} />
            </IconButton>
          </Stack>
          <DialogContent>
            <ol style={{ paddingLeft: "2rem" }}>
              <li> 
                You would be required to answer a qualifying question correctly,
                within the given time period, to be eligible to claim the cash
                Back.{" "}
              </li>
              <li>
                If you do not respond within the given time period, or answer
                any question incorrectly, you will not be eligible for the cash
                Back.{" "}
              </li>
              <li>The cashback will be claimed in single attempt.</li>
              <li>
                Cashback (Amazon/Paytm Pay Gift Card) will be sent to registered
                email id after 10 days of product delivery.{" "}
              </li>

              <li>
                The company shall not be held responsible for any loss, damage,
                or inconvenience arising from the customer's inability to meet
                the offer conditions or any technical issues beyond the
                company's control.
              </li>
              <li>
                This offer is exclusively for the intended recipient and is
                non-transferable.
              </li>
              <li>
                In-case you cancel/return the order, the successfully earned
                cashback will not be given.
              </li>
              <li>Click on proceed now button to start the claim process.</li>
            </ol>

            <FormGroup className="tc-checkbox">
              <FormControlLabel
                onChange={(e) => {
                  setCheck(e?.target?.checked);
                  // console.log(e?.target?.checked);
                }}
                control={<Checkbox aria-disabled />}
                label="I have read, understood & accepted all terms and conditions of the 100% cashback offer and conditions mentioned above."
              />
            </FormGroup>

            <Grid container component="main" sx={{ justifyContent: "center" }}>
              <Grid item xs={12} sm={7} md={7}>
                {check ? (
                  <>
                    <Button
                      className="buttonPrimary medium uppercase"
                      disabled={false}
                      variant="contained"
                      fullWidth
                      sx={{ mb: "25px" }}
                      onClick={handleOpenQnAdialog}
                    >
                      Proceed Now
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      className="buttonPrimary medium uppercase"
                      disabled={true}
                      variant="contained"
                      fullWidth
                      sx={{ mb: "25px" }}
                      onClick={handleOpenQnAdialog}
                    >
                      Proceed Now
                    </Button>
                  </>
                )}
                {/* <Button
                  className="buttonPrimary medium uppercase"
                  disabled={false}
                  variant="contained"
                  fullWidth
                  sx={{ mb: "25px" }}
                  onClick={handleOpenQnAdialog}
                >
                  Proceed Now
                </Button> */}
              </Grid>
            </Grid>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

export default React.memo(TermConditionDialog);
