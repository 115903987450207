import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { DialogTitle, Stack, IconButton, Grid, InputLabel, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';

const EditProfile = ({open, onClose}) => {
    return (
        <div className='profile-outer'>
            <Dialog open={open} onClose={onClose} className="md-dialog">
                <div style={{ width: 818 }}>
                    <Stack className='popupHeader'>
                        <DialogTitle>Edit Profile </DialogTitle>
                        <IconButton disableRipple type="button" onClick={onClose} sx={{ p: '0px', color: '#000' }}>
                            <FontAwesomeIcon icon={faCircleXmark} />
                        </IconButton>
                    </Stack>
                    <DialogContent>

                        <form className='customForm'>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <InputLabel className="customLabel" htmlFor="fname"> First Name </InputLabel>
                                    <TextField
                                        className="customField"
                                        placeholder="Enter First Name"
                                        value=""
                                        fullWidth
                                        id="fname"
                                        autoComplete="off"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel className="customLabel" htmlFor="lname"> Last Name </InputLabel>
                                    <TextField
                                        className="customField"
                                        placeholder="Enter Last Name"
                                        value=""
                                        fullWidth
                                        id="lname"
                                        autoComplete="off"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel className="customLabel" htmlFor="address"> Address </InputLabel>
                                    <TextField
                                        className="customField"
                                        placeholder="Enter Address"
                                        value=""
                                        fullWidth
                                        id="address"
                                        autoComplete="off"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel className="customLabel" htmlFor="contact_number"> Contact Number </InputLabel>
                                    <TextField
                                        className="customField"
                                        placeholder="Enter Contact Number"
                                        value=""
                                        fullWidth
                                        id="contact_number"
                                        autoComplete="off"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel className="customLabel" htmlFor="email"> Email Address </InputLabel>
                                    <TextField
                                        className="customField"
                                        placeholder="Enter Email Address"
                                        value=""
                                        fullWidth
                                        id="email"
                                        autoComplete="off"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel className="customLabel" htmlFor="shop_address"> Shop Address </InputLabel>
                                    <TextField
                                        className="customField"
                                        placeholder="Enter Shop Address"
                                        value=""
                                        fullWidth
                                        id="shop_address"
                                        autoComplete="off"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel className="customLabel" htmlFor="store_id"> Store ID </InputLabel>
                                    <TextField
                                        className="customField"
                                        placeholder="Enter Store ID"
                                        value=""
                                        fullWidth
                                        id="store_id"
                                        autoComplete="off"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel className="customLabel" htmlFor="sname"> Retail Shop Name </InputLabel>
                                    <TextField
                                        className="customField"
                                        placeholder="Enter Shop Name"
                                        value=""
                                        fullWidth
                                        id="sname"
                                        autoComplete="off"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel className="customLabel" htmlFor="bank_detail"> Bank Details </InputLabel>
                                    <TextField
                                        className="customField"
                                        placeholder="Enter Bank Detail"
                                        value=""
                                        fullWidth
                                        id="bank_detail"
                                        autoComplete="off"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel className="customLabel" htmlFor="gst"> GST Number </InputLabel>
                                    <TextField
                                        className="customField"
                                        placeholder="Enter GST Number"
                                        value=""
                                        fullWidth
                                        id="gst"
                                        autoComplete="off"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel className="customLabel" htmlFor="pan_number"> PAN Number </InputLabel>
                                    <TextField
                                        className="customField"
                                        placeholder="Enter PAN Number"
                                        value=""
                                        fullWidth
                                        id="pan_number"
                                        autoComplete="off"
                                    />
                                </Grid>
                                
                                <Grid item xs={12}>
                                    <Button className='buttonPrimary medium uppercase' variant="contained" sx={{ width: '300px', display: 'block', margin: '0 auto 30px' }}> Update </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </div>
            </Dialog>
        </div>
    )
}

export default EditProfile