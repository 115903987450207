import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Suspense, lazy } from 'react';
import Layout from './layouts';
import PrivateRoutes from './routes/PrivateRoutes';
import {Loader} from './components/Loader';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './assets/scss/mainStyle.scss';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { Provider } from 'react-redux';
import store from './redux/store';
import RetailerProfile from './pages/RetailerProfile';
import Notification from './pages/Notifications';

const queryClient = new QueryClient();

const theme = createTheme({
  typography: {
    fontFamily: 'Nunito, sans-serif',
    h1: {
      fontSize: '30px',
      fontWeight: '700',
    },
    h2: {
      fontSize: '20px',
    },
    font22:{
      fontSize: '22px',
    },
    font20:{
      fontSize: '20px',
    },
    font16:{
      fontSize: '16px',
    },
    font14:{
      fontSize: '14px',
    },
    font12:{
      fontSize: '12px',
    },
    body:{
      fontSize:'14px',
    }
  },
  palette: {
    background: {
      default: "#fff"
    },
    primary: {
      main: '#4596F3',
    },
    secondary: {
      main: '#6c757d',
    },
    dark: {
      main: '#000',
    },
  },
});

const LogIn = lazy(() => import("./pages/LogIn"));
const RetailerForgotPassword = lazy(() => import("./components/RetailerForgotPassword"));
const RetailerChangePassword = lazy(() => import("./pages/RetailerChangePassword"));
const Orders = lazy(() => import("./pages/Orders"));
const EyeCheckup = lazy(() => import("./pages/EyeCheckup"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Cart = lazy(() => import("./pages/Cart"));
const Thanks = lazy(() => import("./components/product/Thanks"));
const Reports = lazy(() => import("./pages/Reports"));
const Inventory = lazy(() => import("./pages/Inventory"));
const Payment = lazy(() => import("./components/product/Payment"));
const Lens = lazy(() => import("./pages/Lens"));
const CustomerView = lazy(() => import("./components/CustomerView"));
const Offers = lazy(() => import("./pages/Offers"));
const EyeCheckupForm = lazy(() => import('./components/eyeCheckup/eyeCheckupForm/eyeCheckupForm'));
const ThankYou = lazy(() => import('./components/product/Thanks'));
const OrdeOrderView = lazy(() => import("./components/OrderView"));
const PromoOffers = lazy(() => import("./components/PromoOffers"));
const Checkout = lazy(() => import("./pages/Checkout"));
const KnowledgeBase = lazy(() => import("./pages/KnowledgeBase"));
const ClaimReport = lazy(() => import('./pages/ClaimReport'));
const Appoitments = lazy(() => import('./pages/Appointments'));

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <CssBaseline />
          <BrowserRouter>
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path='/' element={<LogIn />} />
                <Route path='/forgot-password' element={<RetailerForgotPassword />} />
                <Route path='/change-password' element={<RetailerChangePassword />} />
                <Route element={<Layout />}>
                  <Route element={<PrivateRoutes />}>
                    <Route path='/orders' element={<Orders />} />
                    <Route path='/checkup' element={<EyeCheckup />} />
                    <Route path='/dashboard' element={<Dashboard />} />
                    <Route path='/cart' element={<Cart />} />
                    <Route path="/payment" element={<Payment/>} />
                    <Route path='/thank-you' element={<Thanks />} />
                    <Route path='/reports' element={<Reports />} />
                    <Route path='/inventory' element={<Inventory />} />
                    <Route path='/lens' element={<Lens />} />
                    <Route path='/frames' element={<Inventory />} />
                    <Route path='/customer-view' element={<CustomerView />} />
                    <Route path='/offers' element={<Offers />} />
                    <Route path='/new-eye-check' element={<EyeCheckupForm />} />
                    <Route path='/thank-you' element={<ThankYou />} />
                    <Route path='/order-view' element={<OrdeOrderView />} />
                    <Route path='/promo-offers' element={<PromoOffers />} />
                    <Route path='/checkout' element={<Checkout />} />
                    <Route path='/profile' element={<RetailerProfile />} />
                    <Route path='/notifications' element={<Notification />} />
                    <Route path='/knowledge-base' element={<KnowledgeBase/>} />
                    <Route path='/claim-report' element={<ClaimReport />} />
                    <Route path='/appoitments' element={<Appoitments />} />
                  </Route>
                </Route>
              </Routes>
            </Suspense>
          </BrowserRouter>
        </QueryClientProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default App;