import { Outlet, Navigate } from "react-router-dom";
import { isLoggedIn } from "../api/retailerApis";

const PrivateRoutes = () => {

    return(
        <>
            {
                isLoggedIn() ? <Outlet /> : <Navigate to='/' />
            }
        </>
    )
}

export default PrivateRoutes;