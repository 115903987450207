import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import {Loader} from "../components/Loader";
import Filter from "../components/Filter";
import moment from "moment";
import React from "react";
import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  Typography,
  InputBase,
  Box,
  Grid,
  Paper,
  TableContainer,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import NodataImg from '../assets/images/no-data.jpeg';
import { getNotifications, readNotification} from "../api/retailerApis";
import dayjs from "dayjs";

function createData(id, index, subject, description, notificationDate, is_read) {
  return {
    id,
    serial: index,
    subject,
    description,
    notificationDate,
    is_read,
    history: [
      {
        description: description
      },
    ],
  };
}

function Row(props) {
  const { row, refetch } = props;
  const [open, setOpen] = React.useState(false);

  const handleNotificationRead = () => {
    setOpen(!open);
    if(!row.is_read && !open) {
      readNotification({ notification_id: row.id }).then((res) => {
        if(res?.data?.status) {
          refetch();
        }
      })
    }
  }

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }} className={`${row.is_read ? '' : 'unread'}`}>
        <TableCell> {row.serial}</TableCell>
        <TableCell style={{ width: "160px" }}>{row.subject}</TableCell>
        <TableCell className="long-text">{row.description}</TableCell>
        <TableCell>{row.notificationDate}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleNotificationRead}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow className="expand-row">
        <TableCell style={{ padding: 0, border: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 0 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Description</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow, index) => (
                    <TableRow key={index}>
                      <TableCell>{historyRow.description}</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function CollapsibleTable({ rows, refetch }) {
  const columns = ["Id", "Subject", "Description", "Notification Date"];

  return (
    <Table aria-label="collapsible table">
      <TableHead>
        <TableRow>
          {columns.map((column, index) => (
            <TableCell key={index}>{column}</TableCell>
          ))}
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {rows?.map((row) => (
          <Row key={row.id} row={row} refetch={refetch} />
        ))}
      </TableBody>
    </Table>
  );
}

function Notification() {
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const { isLoading, data: notifications, refetch } = useQuery(
    ["notifications"],
    async () => {
      const response = await getNotifications();
      if (response?.data.status) {
        return response.data.notifications;
      }
      return [];
    }, {
    retry: false,
  }
  );

  if (isLoading) {
    return <Loader />;
  }

  const rows = notifications?.filter((notification) => {
    return (search === '' || notification?.notification?.title?.toLowerCase().includes(search.toLowerCase())) &&
      (!fromDate || dayjs().isSame(dayjs(fromDate), 'day') || dayjs().isAfter(dayjs(fromDate), 'day')) &&
      (!toDate || dayjs().isSame(dayjs(toDate), 'day') || dayjs().isBefore(dayjs(toDate), 'day'))
  }).map((row, index) => {
    return createData(
      row.id,
      index + 1,
      row.notification?.title,
      row.notification?.message,
      moment(row.created).format("DD MMMM YYYY hh:mm A"),
      row.is_read
    );
  });

  return (
    <>
      <div className="head-wrap">
        <Typography variant="font22" mb={3} sx={{ fontWeight: "700" }} component="h1">
          Notifications
        </Typography>
      </div>
      <Paper className="tableMainWrap">
        <Grid
          container
          spacing={2}
          sx={{ alignItems: "center" }}
          className="tableFillterWrap" style={{ borderBottom: '1px solid #ccc' }}
        >
          <Grid item xs={12} sm={4}>
            <Paper component="form" className="headerSearchWrap">
              <IconButton
                type="button"
                sx={{ p: "0px", fontSize: "18px", color: "#2B7DCD" }}
                aria-label="search"
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </IconButton>
              <InputBase
                placeholder="Search..."
                inputProps={{ "aria-label": "Search..." }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={8} sx={{ display: "flex", justifyContent: 'flex-end' }}>
            <Filter
              search={true}
              date={true}
              download={false}
              setSearch={setSearch}
              fromDate={fromDate}
              setFromDate={setFromDate}
              toDate={toDate}
              setToDate={setToDate}
            />
          </Grid>
        </Grid>
        <TableContainer className="customTable">
          {
            rows?.length > 0 ?
              <CollapsibleTable rows={rows} refetch={refetch} />
              :
              <div className='no-data-wrap'>
                <img src={NodataImg} alt='no-record' />
                <h4>No record found!</h4>
              </div>
          }
        </TableContainer>
      </Paper>
    </>
  );
}

export default Notification;