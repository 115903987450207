import React, { useState } from 'react';
import SidebarLogo from './../assets/images/sidebar-logo.png';
import { Link, useLocation } from 'react-router-dom';
import eyeCheckup from './../assets/images/eye-checkup.svg';
import inventory from './../assets/images/inventry.svg';
import product from './../assets/images/product.svg';
import customers from './../assets/images/customer.svg';
import './../assets/scss/headerStyle.scss';
import CustomerDetailDialog from '../components/claim/CustomerDetailDialog';
import SignUpModal from '../components/SignUpModal';

function Sidebar() {
  const location = useLocation();
  const [openClaimCDModal, setOpenClaimCDModal] = useState(false);
  // for sub-menu
  const [isVisible, setIsVisible] = useState(false);
  const [isClaimVisible, setIsClaimVisible] = useState(false);
  const [open, setOpen] = useState(false);


  const toggleInventoryMenu = () => {
    setIsVisible(!isVisible);
  };

  const toggleClaimMenu = () => {
    setIsClaimVisible(!isClaimVisible);
  };

  const closeInventoryMenu = () => {
    setIsVisible(false);
  };

  const handleSignUp = () => {
    setOpenClaimCDModal(true);
  }

  return (
    <div >
      <img src={SidebarLogo} alt="Notification" />
      <nav className='retailerSidebar'>
        <ul>
          <li className={`${location.pathname === '/checkup' ? 'active' : ''}`}>
            <Link to="/checkup" onClick={closeInventoryMenu}>
              <img src={eyeCheckup} alt="Eye checkup" />
              Eye checkup
            </Link>
          </li>
          <li className={`${location.pathname === '/orders' ? 'active' : ''}`}>
            <Link to="/orders" onClick={closeInventoryMenu}>
              <img src={product} alt="Order" />
              Order
            </Link>
          </li>
          <li className={`${location.pathname === '/inventory' ? 'active' : ''}`} onClick={toggleInventoryMenu}>
            <Link to="/inventory">
              <img src={inventory} alt="inventory" />
              Inventory
            </Link>
          </li>
          {/* sub-menu */}
          {isVisible &&
            <div className='sub-menu'>
              <ul>
                <li className={`${location.pathname === '/lens' ? 'active' : ''}`}> <Link to="/lens">
                  Lens
                </Link></li>
                <li className={`${location.pathname === '/frames' ? 'active' : ''}`}> <Link to="/frames">
                  Frame
                </Link></li>
              </ul>
            </div>}

          <li className={`${location.pathname === '/reports' ? 'active' : ''}`}>
            <Link to="/reports" onClick={closeInventoryMenu}>
              <img src={customers} alt="Report" />
              Report
            </Link>
          </li>
          <li className={`${location.pathname === '/appoitments' ? 'active' : ''} `}>
            <Link to="/appoitments">
              <img src={customers} alt="Appoitment" />
              Appoitments
            </Link>
          </li>
          {/* <li className={`${location.pathname === '/knowledge-base' ? 'active' : ''}`}>
            <Link to="/knowledge-base" onClick={closeInventoryMenu}>
              <img src={inventory} alt=" Knowledge Base" />
                Knowledge Base
            </Link>
          </li> */}



          <li className={`${location.pathname === '/claim-report' ? 'active' : ''}`}>
            <Link  to="/claim-report"   
            
        
            >
              <img src={product} alt="Claim" />
              Claim Report
            </Link>
          </li>
          {/* sub-menu */}
          {/* {isClaimVisible && */}
          {/* <div className='sub-menu'>
            <ul>
              <li className={`${location.pathname === '/claim-report' }`}>
                 <Link to="/claim-report">
                Claim Report
              </Link></li>
            </ul>
          </div> */}

        </ul>
      </nav>
      <SignUpModal isClaimCheck='true' open={openClaimCDModal} onHide={() => setOpenClaimCDModal(false)} setOpen={setOpen} />
    </div>
  )
}

export default Sidebar;
